import { useEffect, useState } from "preact/hooks";

const DateSelector = ({
	sourceUrl = "https://wza3vjzhdgk2q232p7trclf6dq0mdtss.lambda-url.eu-west-1.on.aws/",
	selectedDate,
	callback,
}) => {
	const [dates, setDates] = useState([]);

	const filterDates = (dateValues) =>
		Promise.resolve(
			dateValues
				.slice(1, -1) // remove oldest and latest (today) entries
				.map((date) => date.replace("stream-", "").replace("/", "")),
		);

	useEffect(() => {
		const now = new Date();
		now.setDate(now.getDate() - 1);
		const yday = now.toISOString().split("T")[0];

		// refresh dropdown dates if unset or day has advanced
		if (!dates.length || dates[dates.length - 1] !== yday) {
			return fetch(sourceUrl)
				.then((res) => res.json())
				.then(filterDates)
				.then(setDates)
				.catch(console.error);
		}
	}, [selectedDate]);

	const onChange = (event) => callback(event.target.value);

	if (!dates.length) return;

	return (
		<select
			id="dates"
			aria-label="Date selection"
			class="bg-gray-50 border border-gray-300 font-bold text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-blue-700 dark:border-blue-600 dark:placeholder-blue-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
			onChange={onChange}
		>
			{dates.map((date) =>
				selectedDate === date ? (
					<option selected value={date}>
						{date}
					</option>
				) : (
					<option value={date}>{date}</option>
				),
			)}
		</select>
	);
};
export { DateSelector };
